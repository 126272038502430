import React from 'react';

import Layout from '../components/Layout';
// import Scroll from '../components/Scroll';

// import config from '../../config';
import Footer from '../components/Footer';
// import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

// import demoImage1 from '../assets/images/demo-image-01.jpg';
// import demoImage2 from '../assets/images/demo-image-02.jpg';
import annaPic from '../assets/images/anna-stepanova-piano.jpg';
import annaPicS from '../assets/images/anna-piano.jpg';

const IndexPage = () => (
  <Layout>
    <Header />

    <section id="about" className="main-bg">
      <div className="container">
        <div className="about-section">
          <h2 className="text-about mb-4">Biography</h2>
          <div className="row">
            <div className="col d-none d-md-block about-square">
              <img
                className="img-fluid about-picture"
                src={annaPicS}
                alt="Anna Stepanova"
              />
            </div>
            <div className="col">
              <p>
                The pianist <strong>Anna Stepanova</strong>, who comes from
                Russia, started playing the piano at the age of 6. Her talent
                was quickly recognized and encouraged. In her teens she won
                several prizes at piano competitions, e.g. at the International
                J. Flier Competition for Young Pianists, the All-Russian Open
                Competition "New Names" and the International Russian
                Competition "Classical Heritage". During this time Anna
                Stepanova also received scholarships from the V. Spivakov
                International Welfare Fund and the Ministry of Culture of the
                Russian Federation and regularly participated in the summer
                music festival "New Names in Russia".
              </p>
            </div>
          </div>
          <p>
            She received her pianistic training at the famous Gnessin Music
            Academy in Moscow with Prof. R. Dieva and I. Malinina. She then
            continued her studies at the University of Music and the Performing
            Arts with Prof. Irina Edelstein and Prof. Bernhard Wetz.
            <br />
            She participated in international master classes with Leon Fleisher,
            M. Woskressenski, Robert Levin, Paul Badura-Skoda, Elisabeth
            Leonskaja and others and received valuable artistic impulses from
            Eberhard Feltz and the cellist of the Fauré Quartet Konstantin
            Heidrich.
          </p>
          <p>
            The young artist has performed at international music festivals in
            Germany, France, Bulgaria and the United States, such as the
            International Music Days Koblenz, the Kronberg Academy Festival, the
            International Keybord Piano Festival New York and the International
            Piano Festival Washington. She has also performed as a soloist with
            several orchestras in Germany and China. Together with her piano duo
            partner Xi Zhai, she has performed in Russia and China as well as at
            festivals, including the Musiktage am Rhein at Schloss Engers and
            the Kronberg Academy Festival.
          </p>
          <p>
            Since November 2016 Anna Stepanova has been supported by Yehudi
            Menuhin Live Music Now Frankfurt am Main, In the years 2017 to 2019
            she also received a Steinway Advancement Award, the Silver Award at
            the "Schumann Inspiration-Competition", the second prize, the
            audience award as well as a sponsorship on the occasion of the Da
            Ponte Foundation competition, the Bayreuth Scholarship of the
            Richard Wagner Association Frankfurt am Main, a Rotary Scholarship
            and twice the Kamar Percy and Ingeborg John Foundation Advancement
            Award.
          </p>
          <div className="row">
            <div className="col-12">
              <img
                className="img-fluid mb-3 float-left col-12 col-md-4 about-picture"
                src={annaPic}
                alt="Anna Stepanova"
              />
              <p>
                She has embarked on a promising concert career with the chamber
                music formations Sigma Trio and the Malbec Piano Quartet, which
                has already taken her to the Rheingau Music Festival, and
                released her first CD with the Sigma Trio in winter 2019.
                Another CD with the Malbec Piano Quartet was released in October
                2020. In 2021 the ensemble won the 1st prize at the Frankfurt
                Polytechnic Society competition.
              </p>
              <p>
                Anna Stepanova has been a coach at master classes with Prof.
                Laura Ruiz Ferreres in Amposta, Spain, as well as at the
                Casalmaggiore International Festival in Italy, and is a tutor
                for sight-reading at the HfMDK Frankfurt.
              </p>
              <p>
                Immediately after the very successful completion of her chamber
                music studies ( class of Prof. Angelika Merkle), she won a
                teaching position for piano at the Hochschule für Musik und
                Darstellende Kunst and an additional teaching position at Dr.
                Hoch's Konservatorium Frankfurt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
